<template>
  <div>
    <div class="container">
      <div class="row justify-content-center" style="margin-top: 50px">
        <div class="col-lg-6">
          <div class="row" style="margin-top: 30px">
            <div class="col">
              <img :src="logoIMG" alt="" class="img-fluid" style="max-height: 60px" />
            </div>
          </div>

          <div class="row mt-4">
            <div class="col">
              <div class="font-weight-bold" style="font-size: 20px">Mari Kita Mulai</div>
              <div style="font-weight: 300" class="text-muted mt-2">Buat akun baru Anda</div>
            </div>
          </div>

          <form @submit.prevent="">
            <div class="row" style="margin-top: 40px">
              <div class="col" style="font-size: 14px">
                <div>
                  <input type="text" class="form-control py-4 border-1" placeholder="Nama Anda..." v-model="filled.name" />
                </div>
                <div>
                  <input type="email" class="form-control py-4 border-1 mt-2" placeholder="Email Anda..." v-model="filled.email" />
                </div>
                <div>
                  <input type="password" class="form-control py-4 border-1 mt-2" placeholder="Password Anda..." v-model="filled.password" />
                </div>
                <div>
                  <input type="password" class="form-control py-4 border-1 mt-2" placeholder="Konfirmasi Password ..." v-model="filled.passwordConfirm" />
                </div>
                <div>
                  <input type="hp" class="form-control py-4 border-1 mt-2" placeholder="No HP/WA..." v-model="filled.phoneNumber" />
                </div>
              </div>
            </div>
          </form>

            <div class="row mt-3">
              <div class="col">
                <button class="btn text-white btn-block py-2 font-weight-bold shadow" style="background-color: #00BF99" @click="register()">Daftar Sekarang</button>
              </div>
            </div>

          <div class="row mt-4" style="font-size: 14px">
            <div class="col">
              <div><span class="text-muted" style="font-weight: 300">Sudah punya akun ? </span> <span @click="goToLogin()">Silahkan Login!</span></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- modal -->

    <div>
      <b-modal id="modal-success" data-keyboard="false" hide-footer centered>
        <p class="my-4 text-center">Berhasil Mendaftar. Silahkan Login</p>

        <div>
          <button class="btn btn-primary btn-block" @click="goToLogin()">Login</button>
        </div>
      </b-modal>
    </div>

  </div>
</template>

<script>
import axios from "axios";
import LogoImport from "../../assets/logo.png";
export default {
  name: "RegisterViews",

  data() {
    return {
      logoIMG: LogoImport,

      filled: {
        name: null,
        email: null,
        phoneNumber: null,
        password: null,
        passwordConfirm: null,
      }
    };
  },

  methods: {
    goToLogin() {
      console.info('-- go to login --');
      this.$router.push("login");
    },

    register() {
      axios.post(process.env.VUE_APP_API + 'register', {
        name: this.filled.name,
        email: this.filled.email,
        phone_number: this.filled.phoneNumber,
        password: this.filled.password,
      }).then((resp) => {
        console.info(resp);
        this.$bvModal.show('modal-success');
      }).catch((err) => {
        console.info(err.response.data);
      })
    }
  },
};
</script>

<style>
</style>